// import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from "app/views/charts/ChartsRoute";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import settingsRoutes from "app/views/settings/SettingsRoutes";
import userRoutes from "app/views/users/UserRoutes";
 //import vendorRoutes from 'app/views/vendors/VendorRoutes'
import catalogRoutes from "./views/catalog/CatalogRoutes";
import EnquiryRoutes from "./views/enquiry/EnquiryRoutes";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import salesRoutes from "app/views/sales/SalesRoutes";
import ReportsRoutes from "app/views/reports/ReportsRoutes";
import cmsRoutes from "app/views/cms/CmsRoutes";
import ConfigureRoute from "./views/Configure/ConfigureRoutes";
import marketplaceRoutes from "app/views/marketplace/MarketplaceRoutes";
import { Navigate } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import MarketingRoutes from "./views/marketing/MarketingRoutes";
// import ConfigureRoute from "./views/configure/ConfigureRoutes";
import ViewRoutes from "./views/viewRoutes";
import profileRoutes from "./views/profile/ProfileRoutes";

const routes = [
  {
    element: (
      //Todo :: remove them
      //<AuthGuard>
      <MatxLayout />
      //</AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...salesRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...settingsRoutes,
      ...userRoutes,
      ...marketplaceRoutes,
      ...catalogRoutes,
      ...cmsRoutes,
      ...MarketingRoutes,
      ...ConfigureRoute,
      ...ViewRoutes,
      ...EnquiryRoutes,
      ...ReportsRoutes,
      ...profileRoutes
    ],
  },
  ...sessionRoutes,
  {
    path: "/",
    element:
      // Check if the access token is present, then navigate to the dashboard, otherwise to signin
      localStorage.getItem("accessToken") ? (
        <Navigate to="/dashboard/default" />
      ) : (
        <Navigate to="/session/signin" />
      ),
  },

  //{ path: '/', element: <Navigate to="dashboard/default" /> },
  //{path:'/loginfailed',element: <Navigate to="/session/signin" /> },
  //{path:'/loginfailed',element: <Navigate to="/session/signin" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
